<template>
  <div class="about">
    <el-card>
      <el-form :model="query" label-width="90px" v-loading="loading">
        <el-row>
          <el-col :span="8">
            <el-form-item label="条件">
              <el-input
                v-model="query.keyword"
                maxlength="30"
                placeholder="公司名称"
                clearable
                @keyup.enter.native="onSearch"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button type="primary" icon="el-icon-download" @click="onExport"
            >导出</el-button
          >
          <slot name="tip"></slot>
        </el-form-item>
      </el-form>

      <el-table
        :data="tableData"
        v-loading="loading"
        @sort-change="onOrder"
        ref="multipleTable"
        border
        fit
      >
        <el-table-column prop="sortNum" label="序号" width="60" align="center">
        </el-table-column>
        <el-table-column prop="companyName" label="公司全称" width="150">
        </el-table-column>
        <el-table-column
          label="联系人"
          width="150"
          prop="linkMan"
        ></el-table-column>
        <el-table-column label="联系电话" prop="linkTel"></el-table-column>
        <el-table-column label="地址" prop="address"></el-table-column>
        <el-table-column label="点击量" prop="clickCount"></el-table-column>
        <el-table-column label="编辑时间" width="160">
          <template slot-scope="scope">
            {{ moment(scope.row.editTime).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="130"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-info"
                @click="handleInfo(scope.row.id)"
                >查看</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-check"
                v-show="scope.row.isDeleted"
                @click="handleReset(scope.row)"
                >上线</el-button
              >
              <el-button
                size="mini"
                type="text"
                icon="el-icon-close"
                v-show="!scope.row.isDeleted"
                @click="handleDelete(scope.row)"
                >下线</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="search"
        @current-change="search"
        :current-page.sync="query.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="query.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <Edit ref="edit"></Edit>
  </div>
</template>
<script>
import { del, reset, adminSearch, exportSearch } from "@/api/company/search.js";
import Edit from "./Edit.vue";

export default {
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        sortFd: null,
        sortBy: null,
      },
      loading: false,
      tableData: [],
      total: 0,
    };
  },
  components: {
    Edit,
  },
  methods: {
    /** 搜索按钮操作 */
    onSearch(queryParams) {
      this.query.pageIndex = 1;
      Object.assign(this.query, queryParams);

      this.search();
    },
    onExport(queryParams) {
      Object.assign(this.query, queryParams);
      this.query.isAdmin = false;
      this.loading = true;
      exportSearch(this.query).then((res) => {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "信息.xls");
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        this.loading = false;
        this.query.isAdmin = true;
      });
    },
    handleInfo(id) {
      // this.$refs.edit.show(id);
      window.open(`/cms/company/show/${id}`);
    },
    handleDelete(row) {
      this.loading = true;
      del(row.id).then(() => {
        this.loading = false;

        this.search();
        this.msgSuccess();
      });
    },
    handleReset(row) {
      this.loading = true;
      reset(row.id).then(() => {
        this.loading = false;

        this.search();
        this.msgSuccess();
      });
    },
    onOrder(value) {
      this.query.orderFd = value.prop;
      this.query.orderBy = value.order;
      this.search();
    },
    search() {
      this.loading = true;
      this.query.isAdmin = true;
      adminSearch(this.query).then((res) => {
        this.tableData = res.datas;
        this.total = res.totalRecords;
        this.loading = false;
      });
    },
  },
  created() {
    this.search();
  },
};
</script>
<style scoped>
.box {
  position: fixed;
  right: 100px;
  bottom: 20px;
  z-index: 100;
}
</style>
