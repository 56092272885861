import request from '@/utils/request'

export function adminSearch(data) {
    return request({
        url: '/company/oper/search',
        method: 'get',
        params: data
    })
}

export function exportSearch(data) {
    return request({
        url: '/company/oper/search/export',
        method: 'post',
        params: data,
        responseType: 'arraybuffer'
    })
}