<template>
  <div>
    <el-dialog
      title="编辑公司信息"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="150px"
        v-loading="loading"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="公司全称" prop="companyName">
              <el-input
                v-model="form.companyName"
                placeholder="请输入公司全称"
                maxlength="300"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="公司所在地" prop="companyArea">
              <el-input
                v-model="form.companyArea"
                placeholder="请输入公司所在地"
                maxlength="300"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="公司简介" prop="introduction">
              <el-input
                type="textarea"
                :rows="3"
                resize="none"
                v-model="form.introduction"
                placeholder="公司简介"
                maxlength="200"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系人" prop="linkMan">
              <el-input
                v-model="form.linkMan"
                placeholder="请输入联系人"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系电话" prop="linkTel">
              <el-input
                v-model="form.linkTel"
                placeholder="请输入联系电话"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系邮箱" prop="linkMail">
              <el-input
                v-model="form.linkMail"
                placeholder="请输入联系邮箱"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="邮编" prop="postCode">
              <el-input
                v-model="form.postCode"
                placeholder="请输入邮编"
                maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="地址" prop="address">
              <el-input
                v-model="form.address"
                placeholder="请输入地址"
                maxlength="100"
                show-word-limit
              />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="官网" prop="officialSite">
              <el-input
                v-model="form.officialSite"
                placeholder="请输入官网"
                maxlength="100"
                show-word-limit
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCompany, updateCompany } from "@/api/company/company.js";

export default {
  name: "Edit",
  data() {
    return {
      visible: false,
      loading: false,
      form: {},
      // 表单校验
      rules: {},
    };
  },
  methods: {
    get(id) {
      this.visible = true;
      this.loading = true;
      getCompany(id).then((res) => {
        this.form = res;
        this.loading = false;
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          updateCompany(this.form.id, this.form)
            .then(() => {
              this.msgSuccess();
              this.loading = false;
              this.visible = false;

              this.$parent.search();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    show(id) {
      this.get(id);
    },
  },
};
</script>

<style></style>
